.serviceContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.serviceHeaderSection {
    width: 100%;
}

.softwareServiceImage {
    height: 15em;
    width: 100%;
    position: relative;
}

.softwareServiceImage img {
    width: 100%;
    height: 100%;
}

.servicesTitle {
    position: absolute;
    z-index: 1; 
    margin-top: 4.5em;
    background: rgba(97, 96, 96, 0.8);
    color: white;
    min-width: 50%;
    padding: 1.5em;
}

.serviceSection {
    margin-top: 4em;
    margin-bottom: 4em;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.serviceCardContainer {
    display: flex;
    justify-content: space-around;
    margin-right: 2em;
    margin-bottom: 2em;
}

.serviceDescription {
    position: relative;
    margin-top: 5em;
    background-color: white;
    padding: 1em;
    width: 20em;
    box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .10) inset;
}

.serviceCard {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;    
    background-color: white;
    width: 13em;
    height: 10em;
    padding: 0.5em;
    border-top-left-radius: 3em;
    box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .10) inset;
}

.serviceImage {
    width: 100%;
    height: 100%;
    background: white;
    border-top-left-radius: 3em;
    padding: 0.5em;
}

.cardTitle {
    width: 100%;
    position: absolute;
    bottom: 0.5em;
    background: rgb(231, 231, 233); 
    background: rgba(231, 231, 233, 0.8);
    padding: 0.6em 0.1em 0.6em 0.1em;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
}

.cardDescription {
    margin-top: 6em;
    font-size: 0.9rem; 
    padding: 0.7em;
    text-align: justify;   
} 

.servicesDetailContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    padding: 2em;
}

.servicesDetailContainer p {
    text-align: justify; 
}

/* Media query for devices upto 768px screens */
@media only screen and (max-width: 768px) {
    .servicesTitle {
        width: 95%;
        text-align: center;      
    }
    .serviceCardContainer {
        margin: 1em;
    }
}

/* Media query for devices upto 540px screens */
@media only screen and (max-width: 540px) {
    .servicesTitle {
        width: 94.5%;
        text-align: center;      
    }
    .serviceCardContainer {
        margin: 1em;
    }
}

/* Media query for devices upto 414px screens */
@media only screen and (max-width: 414px) {
    .servicesTitle {
        width: 93%;
        text-align: center;  
    }
    .serviceCardContainer {
        margin-right: 0; 
    }
}

/* Media query for devices upto 376px screens */
@media only screen and (max-width: 376px) {
    .servicesTitle {
        width: 92%;  
        text-align: center;  
    }
    .serviceCardContainer {
        width: 15em;
        margin-bottom: 2em;
    }
    .serviceCard {
        width: 10em;
        height: 8em;
    }
    .serviceDescription {       
        width: 15em;
    }
    .cardDescription {
        margin-top: 4em;  
    }
}

/* Media query for devices upto 320px screens */
@media only screen and (max-width: 320px) {
    .servicesTitle {
        width: 91%;
        text-align: center;  
    }
    .serviceCardContainer {
        width: 15em;
        margin-right: 0;
        margin: 0 auto;
        margin-bottom: 1em;
    }
    .serviceCard {
        width: 10em;
        height: 8em;
        box-shadow: none;
    }
    .serviceDescription {       
        width: 15em;
        box-shadow: none;
    }
    .cardDescription {
        margin-top: 4em;  
    }
}








/* Contact main container  */
.contactContainer {
    width: 100%;
    margin-top: 7em;
    padding: 3em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

/* section for the contact details */
.detailSection {
    width: 25em;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
}

.offerSection {
    margin-top: 5em;
}

.offerSection > span {
    padding-right: 0.5em;
}

.freeOffersBtn {
    background-color: blue;
    color: white;
    font-size: large;
    margin-right: 0.6em;
}

/* styles for contact form section */
.formSection { 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
}

.contactForm {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inputFields {
    width: 100%;
    background: rgb(231, 231, 233); 
    background: rgba(231, 231, 233, 0.8);
}

.submitBtn {
    width: 6em;
    background-color: blue;
    font-weight: 500;
    margin-left: auto;
    margin-bottom: 1em;
}

/* Media query for devices 1024px width */
@media only screen and (max-width: 1024px) {
    .contactContainer {
        margin-top: 6em;
        margin-bottom: 2em;
        padding: 1.3em;
    }
    .detailSection {
        margin: 0 auto;
        width: 100%;
    }
    .offerSection {
        margin-top: 1em;
        display: flex;
        justify-content: space-around;
    }
    .query {
        font-size: 1.2rem;
    }
    .formSection { 
        margin-top: 1em;
        width: 100%;
    }
}

/* Media query for devices 768px width */
@media only screen and (max-width: 768px) {
    .contactContainer {
        margin-top: 6em;
        margin-bottom: 2em;
        padding: 1.3em;
    }
    .detailSection {
        margin: 0 auto;
        width: 100%;
    }
    .offerSection {
        margin-top: 1em;
        display: flex;
        justify-content: space-around;
    }
    .query {
        font-size: 1.2rem;
    }
    .formSection { 
        margin-top: 1em;
        width: 100%;
    }
}

/* Media query for devices 414px width */
@media only screen and (max-width: 414px) {
    .contactContainer {
        margin-top: 6em;
        margin-bottom: 2em;
        padding: 1.3em;
    }
    .offerSection {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
    }
    .offerSection span {
        margin-bottom: 0.5em;
    }
    .query {
        font-size: 1.2rem;
    }
    .formSection { 
        margin-top: 1em;
        width: 100%;
    }
    .nameEmail {
        display: flex;
        flex-direction: column;
    }
    .submitBtn {
        margin: 0 auto;
        width: 100%;
    }
}

/* Media query for devices 376px width */
@media only screen and (max-width: 376px) {
    .contactContainer {
        margin-top: 6em;
        margin-bottom: 2em;
        padding: 1.3em;
    }
    .offerSection {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
    }
    .offerSection span {
        margin-bottom: 0.5em;
    }
    .query {
        font-size: 1.2rem;
    }
    .formSection { 
        margin-top: 1em;
        width: 100%;
    }
    .nameEmail {
        display: flex;
        flex-direction: column;
    }
    .submitBtn {
        margin: 0 auto;
        width: 100%;
    }
}

/* Media query for devices 320px width */
@media only screen and (max-width: 320px) {
    .contactContainer {
        margin-top: 8em;
        margin-bottom: 2em;
        padding: 0.5em;
    }
    .offerSection {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
    }
    .offerSection span {
        margin-bottom: 0.5em;
    }
    .query {
        font-size: 1.2rem;
    }
    .formSection { 
        margin-top: 1em;
        width: 100%;
    }
    .nameEmail {
        display: flex;
        flex-direction: column;
    }
    .submitBtn {
        margin: 0 auto;
        width: 100%;
    }
}
/* styles for footer main section */
.footerSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 3em 1.5em 3em 1.5em;
    width: 100%;
    text-align: center;
    background: black;
    color: white;
}

/* section headers */
.smartDeveloper {
    display: flex;
    flex-direction: column;
    justify-content: start;
}
/* .logoImage img {
    width: 6em;
    height: 6em;
    border-bottom: 2px solid white;
} */
.logoImage img {
    width: 6em;
    height: 6em;
    border-bottom: 2px solid white;
}
.footerHeader {
    margin-bottom: 0.8em;
    font-size: 1.3rem;
    font-weight: 650;
}

/* about section styles */
.footerAboutUs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    width: 12em;
    line-height: 2em;
    font-size: 1rem;
    font-weight: 450;
}
.footerLinks {
    text-decoration: none;
    color: white;
}
.footerLinks:hover {
    text-decoration: none;
    color: rgb(190, 188, 188);
    cursor: pointer;
}

/* styles for services section in footer  */
.footerServices {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    line-height: 2em;
    font-size: 1rem;
    font-weight: 400;
}
.footerServiceLinks {
    text-decoration: none;
    color: white;
}
.footerServiceLinks:hover {
    text-decoration: none;
    color: white;
    cursor: default;
}

/* styles for social media section */
.socialMeadia {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.socialMeadiaLinks {
    display: flex;
    flex-wrap: wrap;
    padding: 0.4em 0.2em 0.4em 0.2em;
    border-radius: 1em;
}
.socialMediaIcon {
    color: black;
    background: white; 
    padding: 0.3em;
    width: 1.6em;
    height: 1.6em;
    border-radius: 50%;
    margin-right: 0.6em;
}

/* styles for copyright sections */
.copyRightSection {
    padding: 0.5em 2em 0.5em 2em;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    background-color: white;
}

/* Media queries for small devices with 768px width */
@media only screen and (max-width: 768px) {
    .footerSection {
        padding: 4em;
        justify-content: space-between;
    }
    .footerAboutUs {
        justify-content: right;
        padding-left: 3em;
    }
    .socialMeadia {
        margin-top: 1em;
        margin-right: auto;
        margin-left: 1.5em;
    }
    .socialMeadiaLinks {
        padding: 0;
    }
}

/* Media queries for small devices with 540px width */
@media only screen and (max-width: 540px) {
    .footerSection {
        padding: 4em;
        justify-content: space-between;
    }
    .footerServices {
        width: 12em;
        margin-top: 1em;
    }
    .socialMeadia {
        margin-top: 1em;
        margin-right: auto;
        margin-left: 4em;
    }
    .socialMeadiaLinks {
        padding: 0;
    }
}

/* Media queries for small devices with 414px width */
@media only screen and (max-width: 414px) {
    .footerSection {
        padding: 4em;
        justify-content: center;
    }
    .smartDeveloper {
        width: 12em;
        align-items: flex-start;
    }
    .footerAboutUs {
        margin-top: 1em;
        padding-left: 0;
    }
    .footerServices {
        width: 12em;
        margin-top: 1em;
    }
    .socialMeadia {
        padding-top: 1em;
        margin: 0 auto;
        width: 12em;
    }
}

/* Media queries for small devices with 376px width */
@media only screen and (max-width: 376px) {
    .footerSection {
        padding: 2em;
    }
    .smartDeveloper {
        width: 12em;
        align-items: flex-start;
    }
    .footerAboutUs {
        margin-top: 1em;
        padding-left: 0;
    }
    .footerServices {
        width: 12em;
        margin-top: 1em;
    }
    .socialMeadia {
        padding-top: 1em;
        margin: 0 auto;
        width: 12em;
    }
    .socialMeadiaLinks {
        padding: 0;
    }
}

/* Media queries for small devices with 320px width */
@media only screen and (max-width: 320px) {
    .footerSection {
        width: 100%;
        padding: 2em;
    }
    .smartDeveloper {
        width: 12em;
        align-items: flex-start;
    }
    .footerAboutUs {
        margin-top: 1em;
        padding-left: 0;
    }
    .footerServices {
        width: 12em;
        margin-top: 1em;
    }
    .socialMeadia {
        padding-top: 1em;
        margin: 0 auto;
        width: 12em;
    }
    .socialMeadiaLinks {
        padding: 0;
    }
}
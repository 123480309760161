/* navigation bar styles */
.navbar {
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0.5em 1.5em 0.5em 1.5em;
  background-color: white;
}

/* navigation bar links */
.nav-link {
  color: black!important;
  font-size: 16px;
  color: black;
}
.nav-link:hover {
  background: rgba(231, 231, 233, 0.8)!important;
}
.active {
  background-color:white!important;
  border-bottom: 2px solid #0169ca!important;
  font-weight: 'bold'!important;
}
.active:hover {
  color: black!important;
}

/* logo styles */
.logoImageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
}
.companyName {
  font-size: 12px;
  color: black;
  line-height: 0.5rem;
}
.logo {
  width: 2.2em;
  height: 2.2em;
}
.companyLogo {
  width: 100%;
  height: 100%;
}
.caseStudyTitle {
    background: rgba(97, 96, 96, 0.8);
    color: white;
    width: 50%;
    padding: 1.5em;
    margin-top: 1em;
}

.projectSection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2em;
}

/* project modal section styles */
.modalProjectSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2em;
}

/* project Card section  */
.projectCard { 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20em;
    height: 24em;
    margin: 3em 3em 1em 1em;
    background-color: white;    
    cursor: pointer;
    position: relative;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);   
}

.projectCard:hover {
    box-shadow: 0px 5px 10px rgba(35, 18, 63, 0.9);
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.5);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.5);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.5);
    transition: all 200ms ease-in;
    transform: scale(1.5);
}

.projDescriptionSection {
    padding: 1.3em;
    text-align:justify;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column; 
    background: rgba(97, 96, 96, 0.8);
    color: white;
    text-align: center;
    width: 100%;
}

.projectDescription {
    width: 18em;
    font-size: 0.8rem;
}

.showDescription  {
    text-decoration: none!important;
    font-size: 0.8rem;
    color: white;
}

.showDescription:hover {
    color: blue;
}

.projImageSection {
    position: relative;
}

.projectImage {
    width: 100%;
    height: 24em;
    margin-bottom: 2em;
}

/* Media query for devices upto 768px screens */
@media only screen and (max-width: 768px) {
    .caseStudyTitle {
        width: 100%;
        text-align: center;
    }  
    .projectCard {
        margin: 1em 0 1em 0;
    } 
}

/* Media query for devices upto 414px screens */
@media only screen and (max-width: 414px) {
    .caseStudyTitle {
        width: 100%;
        text-align: center;
    }  
    .projectCard {
        margin: 1em 0 1em 0;
    } 
}

/* Media query for devices upto 376px screens */
@media only screen and (max-width: 376px) {
    .caseStudyTitle {
        width: 100%;
        text-align: center;
    }  
    .projectCard {
        margin: 1em 0 1em 0;
    } 
}

/* Media query for devices upto 320px screens */
@media only screen and (max-width: 320px) {
    .caseStudyTitle {
        width: 100%;
        text-align: center;
    }  
    .projectCard {
        width: 18em;
        height: 25em;
        margin: 1em 0 1em 0;
    } 
    .projectImage {
        width: 18em;
        height: 25em;
    } 
}





.toolSectionTitle {
    background: rgba(97, 96, 96, 0.8);
    color: white;
    width: 50%;
    padding: 1.5em;
    margin-top: 1em;
}
.toolSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 3em;
    margin-right: 4em;
    margin-left: 4em;
}
.toolCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5.5em;
    height: 5.5em;
    background-color: white;
    border-radius: 1em;
    padding: 1em;
    margin: 1.5em;
    box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
    cursor: pointer;
}
.toolImage {
    width: 2em;
    height: 2em;
}
.toolName {
    font-size: 0.7rem;
    padding: 0.8em;
    font-family: 'Courier New', Courier, monospace;
    color: blue;
    font-weight: bold;
}
@media only screen and (max-width: 768px) {
    .toolSectionTitle {
        width: 100%; 
        text-align: center;   
    }
}
@media only screen and (max-width: 540px) {
    .toolSectionTitle {
        width: 100%; 
        text-align: center;   
    }
    .toolCard {
        margin: 1em;
    }
    .toolSection {
        margin-right: 0;
        margin-left: 0;
        padding: 2em 0 2em 0;
    }
}
@media only screen and (max-width: 414px) {
    .toolSectionTitle {
        width: 100%; 
        text-align: center;   
    }
    .toolCard {
        margin: 1em;
    }
    .toolSection {
        margin-right: 0;
        margin-left: 0;
        padding: 2em 0 2em 0;
    }
}
@media only screen and (max-width: 376px) {
    .toolSectionTitle {
        width: 100%; 
        text-align: center;   
    }
    .toolCard {
        margin: 1em;
    }
    .toolSection {
        margin-right: 0;
        margin-left: 0;
        padding: 2em 0 2em 0;
    }
}
@media only screen and (max-width: 320px) {
    .toolSectionTitle {
        width: 100%; 
        text-align: center;   
    }
    .toolCard {
        margin: 1em;
    }
    .toolSection {
        margin-right: 0;
        margin-left: 0;
        padding: 2em 0 2em 0;
    }
}

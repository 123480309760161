.pCatalogTitle {
    background: rgba(177, 175, 175, 0.8);
    color: white;
    width: 50%;
    padding: 1.5em;
    margin-top: 1em;
}

.catalogDescription {
    width: 50%;
    padding-top: 2em;
}

.offerSection {
    width: 100%;
    padding-bottom: 1em;    
}

.offerTitle {
    text-align: center;
    padding-bottom: 1em;
}

.offerSection ul {
    display: flex;
    justify-content: space-around;
}

.catalogContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.catalogCard {
    width: 23em;
    margin-bottom: 2em;
    padding: 2em;
    background-color: white;
    box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .10) inset;
}

.catalogVideo {
    width: 100%;
    height: 15.6em;
}

.catalogPriceAndLink {
    display: flex;
    flex-direction: column;
}

.pcLinkBtn {
    height: 1.9em;
    width: 10em;
    text-align: center;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    color: black;
    margin-top: .5em;
}

.pcLinkBtn a:focus {
    outline: none !important;
}

.pcLinkBtn:hover {
    text-decoration: none;
}

/* Media query for devices upto 1024px screens */
@media only screen and (max-width: 1024px) {
    .catalogDescription {
        text-align: justify; 
    } 
    .offerSection ul {
        display: flex;
        flex-direction: column;
    }  
    .catalogCard {
        width: 20em;        
    }
}

/* Media query for devices upto 768px screens */
@media only screen and (max-width: 768px) {
    .offerSection ul {
        display: flex;
        flex-direction: column;
    }  
    .catalogCard {
        width: 21em;        
    }
}

/* Media query for devices upto 540px screens */
@media only screen and (max-width: 540px) {
    .pCatalogTitle {
        width: 60%;
    }
    .catalogDescription {
        width: 60%;
        text-align: justify; 
    }  
    .offerTitle {
        font-size: 1.3rem; 
        text-align: left;
    }
    .offerSection ul {
        display: flex;
        flex-direction: column;
    }  
    .catalogCard {
        width: 32em;        
    }
}

/* Media query for devices upto 414px screens */
@media only screen and (max-width: 414px) {
    .pCatalogTitle {
        width: 100%;
        text-align: center;
    }
    .catalogDescription {
        width: 100%;
        text-align: justify;
    }  
    .offerTitle {
        margin-top: 1em;
        font-size: 1.5rem;
    }
    .offerSection ul {
        display: flex;
        flex-direction: column;
    }  
    .catalogCard {
        width: 23em;        
    }
}

/* Media query for devices upto 376px screens */
@media only screen and (max-width: 376px) {
    .pCatalogTitle {
        width: 100%;
        text-align: center;
    }
    .catalogDescription {
        width: 100%;
        text-align: justify;
    }  
    .offerTitle {
        margin-top: 1em;
        font-size: 1.5rem;
    }
    .offerSection ul {
        display: flex;
        flex-direction: column;
    }  
    .catalogCard {
        width: 22em;        
    }
}

/* Media query for devices upto 320px screens */
@media only screen and (max-width: 320px) {
    .pCatalogTitle {
        width: 100%;
        text-align: center;
    }
    .catalogDescription {
        width: 100%;
        text-align: justify;
    }  
    .offerTitle {
        margin-top: 1em;
        font-size: 1.5rem;
    }
    .offerSection ul {
        display: flex;
        flex-direction: column;
    }  
    .catalogCard {
        width: 18em;        
    }
}

/* Media query for devices upto 280px screens */
@media only screen and (max-width: 280px) {
    .pCatalogTitle {
        width: 100%;
        text-align: center;
        padding: 0.5em;
        font-size: 1.2rem;
    }
    .catalogDescription {
        width: 100%;
        text-align: justify;
    }  
    .offerTitle {
        margin-top: 1em;
        font-size: 1.5rem;
    }
    .offerSection ul {
        display: flex;
        flex-direction: column;
    }  
    .catalogCard {
        width: 16em;        
    }
}
/* About main section styles */
.aboutContainer {
    margin-top: 7em;
    background-color: white;
    padding: 1em;
}

.objective {
    width: 90%;
    text-align: justify;
    padding-top: 2em;
    margin: 0 auto;
}

.description {
    font-size: 16px;
    font-weight: 550;
}

/* profiles section style */
.profilesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.sectionTitle {
    margin: 2em 0 1em 0;
    text-align: center;
    font-weight: 200;
}

/* each profile card styles */
.profileCard {
    width: 15em;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1em;
    opacity: 1;
}

.profileCard:hover {
    border: 1px solid rgb(201, 200, 200);
    border-radius: 1em;
}

.profileImage {
    width: 10em;
    height: 10em;
}

.profilePic {
    width: 10em;
    height: 10em;
    border-radius: 50%;
}

.profileDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
}

.personalInformation {
    font-size: 0.8rem;
}

/* Media query for devices upto 700px screens */
@media only screen and (max-width: 700px) {
    .objective {
        width: 85%;
        text-align: justify;
        margin: 1.8em;
    }
}

/* Media query for devices upto 540px screens */
@media only screen and (max-width: 540px) {
    .objective {
        width: 85%;
        text-align: justify;
        margin: 1.8em;
    }
}

@media only screen and (max-width: 500px) {
    .objective {
        width: 85%;
        text-align: justify;
        margin: 1.8em;
    }
}

/* Media query for devices upto 414px screens */
@media only screen and (max-width: 414px) {
    .objective {
        width: 80%;
        text-align: justify;
        margin: 1.8em;
    }
}

/* Media query for devices upto 376px screens */
@media only screen and (max-width: 376px) {
    .objective {
        width: 80%;
        text-align: justify;
        margin: 1.8em;
    }
}

/* Media query for devices upto 320px screens */
@media only screen and (max-width: 320px) {
    .objective {
        width: 80%;
        text-align: justify;
        margin: 1.8em;
    }
}

/* Media query for devices upto 280px screens */
@media only screen and (max-width: 280px) {
    .objective {
        width: 75%;
        text-align: justify;
        margin: 1.8em;
    }
}
.popUpInner {
    z-index: 2;
    position: fixed;
    text-align: center;
    padding: 0.2em;
    top: 75%; 
    left: 88%;    
    width: 85px;
    height: 65px;
    cursor: pointer;
    color: white;
    background: black;
    border-radius: 50px / 40px;
    background: linear-gradient(to left, #000000 #000000);
}

/* creates triangle */
.popUpInner:after {
    content: "";
    display: block; /* reduce the damage in FF3.0 */
    position: absolute;
    bottom: -13px;
    left: 5px;
    width: 0;
    border-width: 25px 8px 0 8px;
    border-style: solid;
    border-color: black transparent;
    -ms-transform: rotate(35deg); /* IE 9 */
    -webkit-transform: rotate(35deg); /* Chrome, Safari, Opera */
    transform: rotate(35deg);
}

.speechBubble {
    font-size: 2.5rem;
    font-weight: bold;
}

.speechBubble:hover {
    animation-name: rotate; 
    animation-duration: 2s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.buttonContainer {
    color: red;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 0;
}

.buttonContainer:hover {
    font-size: 1.8rem;
    background: black;
    color: white;
    opacity: 1;
}

/* Media query for devices upto 1024px screens */
@media only screen and (max-width: 1024px) {
    .popUpInner {
       left: 84%;
    }
}

/* Media query for devices upto 768px screens */
@media only screen and (max-width: 768px) {
    .popUpInner {
       left: 82%;
    }
}

/* Media query for devices upto 540px screens */
@media only screen and (max-width: 540px) {
    .popUpInner {
       visibility: hidden;
    }
}

/* Media query for devices upto 414px screens */
@media only screen and (max-width: 414px) {
    .popUpInner {
       visibility: hidden;
    }
}

/* Media query for devices upto 376px screens */
@media only screen and (max-width: 376px) {
    .popUpInner {
       visibility: hidden;
    }
}

/* Media query for devices upto 320px screens */
@media only screen and (max-width: 320px) {
    .popUpInner {
       visibility: hidden;
    }
}

/* Media query for devices upto 280px screens */
@media only screen and (max-width: 280px) {
    .popUpInner {
       visibility: hidden;
    }
}
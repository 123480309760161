/* indication button for scroll to down button style   */
.scrollToDown {
    position: fixed;
    top: 30em;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgb(187, 9, 9);
    color: #ffffff;
    z-index: 2;
}
.scrollToDown:hover {
    text-rendering: "Scroll down";
    cursor: pointer;
}
.iconDown {
    font-size: 19px;
}
.scrollToDown .iconDown {
    margin-top: 10px;
    animation: scrollTo 0.5s alternate ease infinite;
}
/* scroll to top button style   */
.scrollToTop {
    position: fixed;
    bottom: 12px;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgb(187, 9, 9);
    color: #ffffff;
    z-index: 2;
    cursor: pointer;
}
.icon {
    font-size: 19px;
}
.scrollToTop .icon {
    margin-top: 10px;
    animation: scrollTo 0.5s alternate ease infinite;
}
@keyframes scrollTo{
    from{
        transform: translateY(2px);
    }
    to{
        transform: translateY(-1px);
    }
}
/* Media query for devices upto 1024px screens */
@media only screen and (max-width: 1024px) {
    .scrollToDown {
        /* margin: 0 auto;   */
        top: 34em;     
    }
}
/* Media query for devices upto 768px screens */
@media only screen and (max-width: 768px) {
    .scrollToDown {
        top: 26em;     
    }
}
/* Media query for devices upto 540px screens */
@media only screen and (max-width: 540px) {
    .scrollToDown {
        left: 45%;
        top: 20em;     
    }
}
/* Media query for devices upto 414px screens */
@media only screen and (max-width: 414px) {
    .scrollToDown {
        left: 45%;
        top: 16em;     
    }
}
/* Media query for devices upto 376px screens */
@media only screen and (max-width: 376px) {
    .scrollToDown {
        left: 45%;
        top: 15em;     
    }
}
/* Media query for devices upto 320px screens */
@media only screen and (max-width: 320px) {
    .scrollToDown {
        left: 45%;
        top: 13em;     
    }
}
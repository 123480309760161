/* Hero section styles */
.heroSection {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.bannerImage {
    width: 100%;
}
.bannerImage img {
    width: 100%;
}
.bannerSection {
    display: flex;
    min-width: 100%;
    position: relative;
}
.motivation {
    position: absolute;
    color: white;
    width: 65%;
    align-self: center;
    padding: 3.5em;
}

/* main section styles and lower section of hero section */
.mainSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: white;
}
.bannerDescription {
    text-align: left;
    font-weight: bold;
}

/* Styles for the slider component */
.slider {
    position: absolute;
    width: 100%;
    color: white;
    text-align: center;
    margin-top: 28%;
    font-size: 1.3rem;
    font-weight: 400;
}
.imageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25em;
}
.imageContainer img {
    width: 100%;
}
.imageContainer h2 {
    text-align:center;
}
.imageContainer .content {
    position: absolute;
    bottom: 1em;
    background: rgba(97, 96, 96, 0.8);
    color: white;
    width: 100%;
    padding: 3em;
}

/* styles for the quote section which directs to contact form */
.getQuoteSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2em;
    width: 60%;
}
.getQuoteSection p {
    color: #048E86;
}
.weFocus {
    text-align: center;
    font-size: 0.8rem;
}
.devTeam {
    width: 60%;
    font-size: 0.9rem;
    text-align: center;
}
.getQuoteSection > button {
    margin-top: 6em;
    height: 1.9em;
    width: 10em;
    text-align: center;
    border-radius: 1.6em;
    border: none;
    background-color: #1E06B2;
    color: white;
    font-size: 0.9rem;
    text-decoration: none;
    cursor: pointer;
    padding: 0.2em;
    box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
}
.getQuoteSection button:focus {
    outline: none !important;
}
.getQuoteSection a:hover {
    color: white;
}

/* Media query for devices upto 1024px screens */
@media only screen and (max-width: 1024px) {
    .motivation {
        width: 100%;
    }
    .bannerDescription {
        text-align: center;
    }
    .imageContainer {
        margin: 0 auto;
     }
    .getQuoteSection {
        margin: 0 auto;
        width: 100%;
    } 
    .devTeam {
        width: 60%;
        font-size: 0.6rem;
        text-align: center;
    }   
}

/* Media query for devices upto 768px screens */
@media only screen and (max-width: 768px) {
    .motivation {
        width: 100%;
    }
    .bannerDescription {
        text-align: center;
    }
    .imageContainer {
        margin: 0 auto;
     }
    .getQuoteSection {
        margin: 0 auto;
        width: 100%;
    } 
    .devTeam {
        width: 70%;
        font-size: 0.6rem;
        text-align: center;
    }   
}

/* Media query for devices upto 540px screens */
@media only screen and (max-width: 540px) {
    .motivation {
        width: 100%;
        padding: 2em;
    }
    .bannerDescription {
        font-size: 1.5rem;
    }
    .imageContainer {
       margin: 0 auto;
    }
    .imageContainer h2 {
        font-size: 1.5rem;
    }
    .slider {
        margin-top: 30%;
    }
    .getQuoteSection h2 {
        font-size: 1.5rem;
    }
    .devTeam {
        width: 60%;
        font-size: 0.6rem;
        text-align: center;
    }
}

/* Media query for devices upto 414px screens */
@media only screen and (max-width: 414px) {
    .bannerImage {
        height: 75%;
    }
    .motivation {
        width: 100%;
        padding: 1em;
    }
    .bannerDescription {
        font-size: 1.5rem;
        text-align: center;
    }
    .imageContainer h2 {
        font-size: 1.5rem;
    }
    .slider {
        margin-top: 32%;
        font-size: 1rem;
        font-weight: 300;
    }
    .getQuoteSection {
        margin: 0 auto;
        width: 100%;
    }
    .devTeam {
        width: 78%;
        font-size: 0.6rem;
        text-align: center;
    }
    .getQuoteSection h2 {
        font-size: 1.5rem;
    }
}

/* Media query for devices upto 376px screens */
@media only screen and (max-width: 376px) {
    .bannerImage {
        height: 75%;
    }
    .motivation {
        width: 100%;
        padding: 1em;
    }
    .bannerDescription {
        font-size: 1.2rem;
        text-align: center;
    }
    .imageContainer h2 {
        font-size: 1.4rem;
    }
    .slider {
        margin-top: 32%;
        font-size: 1rem;
        font-weight: 300;
    }
    .getQuoteSection {
        margin: 0 auto;
        width: 100%;
    }
    .devTeam {
        width: 90%;
        font-size: 0.6rem;
        text-align: center;
    }
    .getQuoteSection h2 {
        font-size: 1.4rem;
    }
}

/* Media query for devices upto 320px screens */
@media only screen and (max-width: 320px) {
    .motivation {
        padding: 1em;
    }
    .bannerImage {
        width: 100%;
    }
    .bannerImage img {
        width: 100%;
    }
    .bannerDescription {
        font-size: 1rem;
        text-align: center;
    }
    .imageContainer h2 {
        font-size: 1.1rem;
    }
    .slider {
        margin-top: 32%;
        font-size: 0.8rem;
    }
    .getQuoteSection {
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }
    .devTeam {
        width: 78%;
        font-size: 0.6rem;
        text-align: center;
    }
    .getQuoteSection h2 {
        font-size: 1.1rem;
    }
    .weFocus {
        font-size: 0.6rem;
    }
}